// src/app/http-error.interceptor.ts
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, retry, switchMap, tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { TokenService } from '../services/token.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private authService: AuthService,
    private tokenService: TokenService,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let updatedRequest = request;

    // Only add the Authorization header when on the browser
    if (isPlatformBrowser(this.platformId)) {
      const accessToken = this.tokenService.getAccessToken();
      if (accessToken) {
        const headers = new HttpHeaders().set(
          'Authorization',
          `Bearer ${accessToken}`
        );
        updatedRequest = request.clone({ headers });
      }
    }

    return next.handle(updatedRequest).pipe(
      retry(1),
      tap((event) => {
        if (event instanceof HttpResponse) {
          // Optional: Handle HTTP Response data here
        }
      }),
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        if (isPlatformBrowser(this.platformId)) {
          // Browser-specific error handling
          if (error.status === 401) {
            // Handle 401 errors (token expired)
            return this.handle401Error(request, next);
          }
          if (error.error instanceof ErrorEvent) {
            errorMessage = `Error: ${error.error.message}`;
          } else {
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
          }
        } else if (isPlatformServer(this.platformId)) {
          // Server-specific error handling
          if (error.status === 401) {
            // Handle 401 errors similarly on the server if needed
            // Here you might handle it differently or log it, as you cannot directly interact with the user
            console.error('Unauthorized access attempt in SSR');
          }
          errorMessage = `Server Error Code: ${error.status}\nMessage: ${error.message}`;
        }

        console.error(errorMessage);
        return throwError(errorMessage);
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    const returnUrl = this.router.url;
    return this.authService.refreshToken().pipe(
      switchMap(
        (response: {
          accessToken: string;
          refreshToken: string;
          userId: string;
        }) => {
          if (
            response &&
            response.accessToken &&
            response.refreshToken &&
            response.userId
          ) {
            this.tokenService.saveToken(
              response.accessToken,
              response.refreshToken
            );
            this.authService.setUserId(response.userId);
            const headers = new HttpHeaders().set(
              'Authorization',
              `Bearer ${response.accessToken}`
            );
            const clonedRequest = request.clone({ headers });
            return next.handle(clonedRequest);
          } else {
            this.router.navigate(['/sign-in'], {
              queryParams: { return_url: returnUrl },
            });
            return throwError('Unable to refresh token');
          }
        }
      ),
      catchError((err) => {
        console.error(
          'Error during token refresh or subsequent request handling',
          err
        );
        this.authService.logout();
        // redirect to the login page with return url as parameter return_url
        this.router.navigate(['/sign-in'], {
          queryParams: { return_url: returnUrl },
        });
        return throwError(err);
      })
    );
  }
}
