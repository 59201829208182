import { Component } from '@angular/core';

@Component({
  selector: 'gugudata-key-benefits',
  standalone: true,
  imports: [],
  templateUrl: './key-benefits.component.html',
  styleUrl: './key-benefits.component.scss',
})
export class KeyBenefitsComponent {}
