import {
  CommonModule,
  isPlatformBrowser,
  isPlatformServer,
} from '@angular/common';
import { AfterViewInit, Component, Inject, PLATFORM_ID } from '@angular/core';
import { RouterLink } from '@angular/router';

declare var HSGoTo: any;

@Component({
  selector: 'gugudata-footer',
  standalone: true,
  imports: [RouterLink, CommonModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent implements AfterViewInit {
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}
  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.initializeExternalScripts();
    }
    if (isPlatformServer(this.platformId)) {
      // 服务器特定的代码
    }
  }

  private initializeExternalScripts() {
    new HSGoTo('.js-go-to');
  }
}
