<main id="content" role="main">
  <gugudata-intro-banner></gugudata-intro-banner>

  <gugudata-key-benefits></gugudata-key-benefits>

  <gugudata-category-list></gugudata-category-list>

  <gugudata-why-gugudata></gugudata-why-gugudata>

  <gugudata-customer-feedback></gugudata-customer-feedback>
</main>
