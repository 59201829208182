import { Component } from '@angular/core';

@Component({
  selector: 'gugudata-why-gugudata',
  standalone: true,
  imports: [],
  templateUrl: './why-gugudata.component.html',
  styleUrl: './why-gugudata.component.scss',
})
export class WhyGugudataComponent {}
