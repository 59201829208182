<header
  id="header"
  class="navbar navbar-expand-lg navbar-end navbar-absolute-top navbar-light bg-white navbar-show-hide pt-0"
  data-hs-header-options='{
          "fixMoment": 1000,
          "fixEffect": "slide"
        }'
>
  <div class="container">
    <nav class="js-mega-menu navbar-nav-wrap">
      <!-- Default Logo -->
      <a class="navbar-brand" role="button" routerLink="/" aria-label="Front">
        <img
          class="navbar-brand-logo"
          src="/assets/svg/logos/logo.svg"
          alt="gugudata logo"
        />
      </a>
      <!-- End Default Logo -->

      <!-- Toggler -->
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-default">
          <i class="bi-list"></i>
        </span>
        <span class="navbar-toggler-toggled">
          <i class="bi-x"></i>
        </span>
      </button>
      <!-- End Toggler -->

      <!-- Collapse -->
      <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <div class="navbar-absolute-top-scroller">
          <ul class="navbar-nav">
            <!-- Landings -->
            <li class="nav-item">
              <a
                id="landingsMegaMenu"
                class="nav-link"
                aria-current="page"
                routerLink="/list"
                role="button"
                i18n
                >Data and APIs</a
              >
            </li>

            <li class="nav-item">
              <a
                id="developerMegaMenu"
                class="nav-link"
                role="button"
                i18n
                target="_blank"
                href="https://gugudata.statuspage.io"
                >Status&nbsp;<i class="bi-box-arrow-up-right small ms-1"></i
              ></a>
            </li>
            <li class="nav-item">
              <a
                id="developerMegaMenu"
                class="nav-link"
                role="button"
                i18n
                routerLink="/aboutus"
                >Company</a
              >
            </li>
            <li class="hs-has-sub-menu" *ngIf="isLoggedIn">
              <a
                id="companyMegaMenu"
                class="hs-mega-menu-invoker nav-link dropdown-toggle"
                href="#"
                role="button"
                aria-expanded="false"
                i18n
                >Console</a
              >
              <div
                class="hs-sub-menu dropdown-menu"
                aria-labelledby="companyMegaMenu"
                style="min-width: 14rem"
              >
                <a class="dropdown-item" routerLink="/dashboard"
                  >Subscriptions</a
                >
                <a class="dropdown-item" routerLink="/dashboard/account-info"
                  >Account</a
                >
              </div>
            </li>

            <li *ngIf="isLoggedIn">
              <div>
                <button
                  type="button"
                  class="btn btn-outline-secondary btn-sm"
                  i18n
                  (click)="logout()"
                >
                  Logout
                </button>
              </div>
            </li>

            <li *ngIf="!isLoggedIn">
              <div>
                <button
                  type="button"
                  class="btn btn-primary btn-sm"
                  i18n
                  (click)="gotoSignIn()"
                >
                  Sign in</button
                >&nbsp;
                <button
                  type="button"
                  class="btn btn-white btn-sm"
                  i18n
                  (click)="gotoSignUp()"
                >
                  Sign up
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- End Collapse -->
    </nav>
  </div>
</header>
