<div class="container content-space-t-4 content-space-t-lg-3">
  <div class="row justify-content-lg-between align-items-lg-center mb-10">
    <div class="col-md-7 col-lg-6">
      <div class="mb-5">
        <h1>GuGuData, Your Professional Data Hub</h1>
        <p>
          Elevate from the Basics to Brilliance. Transform your data-driven
          vision into reality effortlessly. The ultimate data solution for
          analysts, businesses, researchers, and innovators.
        </p>
      </div>
      <div class="d-grid d-sm-flex gap-3">
        <a class="btn btn-primary btn-transition" href="/sign-up"
          >Get started</a
        >
        <a class="btn btn-link" href="/list"
          >View Data & APIs<i class="bi-chevron-right small ms-1"></i
        ></a>
      </div>
      <p class="form-text small">Start free trial. *No credit card required.</p>
    </div>
    <div class="col-sm-6 col-md-5 d-none d-md-block">
      <img
        class="img-fluid"
        src="assets/svg/illustrations/oc-growing.svg"
        alt="oc-growing"
      />
    </div>
  </div>
</div>
