import { Component } from '@angular/core';
import { MetaService } from '@app/core/services/meta.service';
import { CategoryListComponent } from '../../widget/category-list/category-list.component';
import { CustomerFeedbackComponent } from '../../widget/customer-feedback/customer-feedback.component';
import { IntroBannerComponent } from '../../widget/intro-banner/intro-banner.component';
import { KeyBenefitsComponent } from '../../widget/key-benefits/key-benefits.component';
import { WhyGugudataComponent } from '../../widget/why-gugudata/why-gugudata.component';
@Component({
  selector: 'gugudata-index',
  standalone: true,
  imports: [
    KeyBenefitsComponent,
    CategoryListComponent,
    WhyGugudataComponent,
    CustomerFeedbackComponent,
    IntroBannerComponent,
  ],
  templateUrl: './index.component.html',
  styleUrl: './index.component.scss',
})
export class IndexComponent {
  constructor(private metaService: MetaService) {
    this.metaService.updateDefaultMetaData();
  }
}
