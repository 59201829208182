import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';

declare var HSHeader: any;
declare var HSMegaMenu: any;
declare var HSShowAnimation: any;

@Component({
  selector: 'gugudata-header',
  standalone: true,
  imports: [RouterLink, CommonModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements AfterViewInit, OnInit {
  isLoggedIn: boolean = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.initializeExternalScripts();
    }
  }

  ngOnInit() {
    this.authService.isLoggedIn.subscribe((isLoggedIn) => {
      this.isLoggedIn = isLoggedIn;
    });
  }

  gotoSignIn() {
    this.router.navigate(['/sign-in']);
  }

  gotoSignUp() {
    this.router.navigate(['/sign-up']);
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/']);
  }

  initializeExternalScripts() {
    if (typeof HSHeader !== 'undefined' && HSHeader !== null) {
      new HSHeader('#header').init();
    }

    if (typeof HSMegaMenu !== 'undefined' && HSMegaMenu !== null) {
      new HSMegaMenu('.js-mega-menu', {
        desktop: {
          position: 'left',
        },
      });
    }

    if (typeof HSShowAnimation !== 'undefined' && HSShowAnimation !== null) {
      new HSShowAnimation('.js-animation-link');
    }
  }
}
