<div
  class="container content-space-2 content-space-t-xl-2 content-space-b-lg-2"
>
  <div class="text-center mb-5">
    <img
      class="avatar avatar-lg avatar-4x3"
      src="assets/svg/illustrations/oc-person-1.svg"
      alt="avatar"
    />
  </div>

  <!-- Blockquote -->
  <figure class="w-md-75 text-center mx-md-auto">
    <blockquote class="blockquote">
      “At GuGuData, we're setting the new standard for data management and
      analytics. Our commitment is to empower businesses, researchers, and
      innovators with the most comprehensive and adaptable data solutions
      available today.”
    </blockquote>

    <figcaption class="blockquote-footer text-center">
      Future Forward
      <span class="blockquote-footer-source"
        >Embracing Data-Driven Excellence</span
      >
    </figcaption>
  </figure>
  <!-- End Blockquote -->
</div>
