import { Component } from '@angular/core';

@Component({
  selector: 'gugudata-customer-feedback',
  standalone: true,
  imports: [],
  templateUrl: './customer-feedback.component.html',
  styleUrl: './customer-feedback.component.scss',
})
export class CustomerFeedbackComponent {}
