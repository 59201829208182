import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cfcdn',
  standalone: true,
})
export class CFCDNPipe implements PipeTransform {
  transform(value: string, size: 'small' | 'middle' | 'large'): string {
    if (!value) {
      return '';
    }
    switch (size) {
      case 'small':
        return `https://cdn.gugudata.io/cdn-cgi/image/width=140,quality=75/${value}`;
      case 'middle':
        return `https://cdn.gugudata.io/cdn-cgi/image/width=500,quality=95/${value}`;
      case 'large':
        return `https://cdn.gugudata.io/cdn-cgi/image/width=900,quality=100/${value}`;
      default:
        return value;
    }
  }
}
